import React from "react"
import NetlifyForm from "react-netlify-form"
import { TTheme } from "../../types"
import { DefaultButton } from "./button"
import { Typography } from "./typography"
import styled from "../theme/styled"

interface ContactFormProps {
	subject?: string
}

const Input = styled.input(({ theme }) => ({
	fontSize: theme.font.size.s,
	width: `100%`,
	lineHeight: `1.5em`,
	borderWidth: `2px`,
	borderStyle: `solid`,
	padding: `.2em .6em`,
	borderColor: theme.colours.primary,
	background: theme.colours.inverted,
	color: theme.colours.on.inverted,
	"&[readonly]": {
		outline: 0,
		color: theme.colours.tones.neutral,
	},
}))

const Textarea = styled.textarea(({ theme }) => ({
	fontSize: theme.font.size.s,
	display: `block`,
	width: `100%`,
	lineHeight: `1.5em`,
	borderWidth: `2px`,
	borderStyle: `solid`,
	padding: `.2em .6em`,
	borderColor: theme.colours.primary,
	background: theme.colours.inverted,
	color: theme.colours.on.inverted,
}))

export function ContactForm({ subject }: ContactFormProps) {
	return (
		<NetlifyForm name="Contact Form">
			{({
				loading,
				error,
				success,
			}: {
				loading: boolean
				error: string
				success: boolean
			}) => (
				<>
					{loading && <Typography>Submitting message..</Typography>}
					{error && (
						<Typography>
							We are sorry to say that your message failed to
							send. Please try again later! If the problem occurs,
							please e-mail us.
						</Typography>
					)}
					{success && (
						<Typography>Thank you for contacting us!</Typography>
					)}
					{!loading && !success && (
						<>
							<div
								css={(theme: TTheme) => ({
									display: `grid`,
									gridColumnGap: theme.spacing.l,
									gridTemplateColumns: `repeat(2, 1fr)`,
									marginBottom: theme.spacing.l,
								})}
							>
								<Input
									type="text"
									name="firstName"
									required
									placeholder="First name"
								/>
								<Input
									type="text"
									name="lastName"
									placeholder="Last name"
								/>
							</div>
							<Input
								type="email"
								name="e-mail"
								placeholder="Email address"
								required
								css={(theme: TTheme) => ({
									marginBottom: theme.spacing.l,
								})}
							/>
							{subject && (
								<Input
									type="text"
									name="subject"
									placeholder="Subject"
									value={subject}
									css={(theme: TTheme) => ({
										marginBottom: theme.spacing.l,
									})}
									readOnly
								/>
							)}
							<Textarea
								name="message"
								required
								placeholder="Your message"
								css={(theme: TTheme) => ({
									marginBottom: theme.spacing.l,
								})}
								rows={7}
							/>
							<DefaultButton
								type="submit"
								css={{ width: `100%` }}
							>
								Submit
							</DefaultButton>
						</>
					)}
				</>
			)}
		</NetlifyForm>
	)
}
