import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { TTheme } from "../../types"
import { ContactForm } from "../components/contact-form"
import { Container } from "../components/container"
import { H1, H2 } from "../components/headings"
import { Typography } from "../components/typography"
import mq from "../theme/mq"
import styled from "../theme/styled"

const MailtoTypography = styled(Typography.withComponent("a"))({
	textDecoration: `none`,
	color: `inherit`,
	":hover": {
		textDecoration: `underline`,
	},
})

const TelTypography = styled(Typography.withComponent("a"))({
	textDecoration: `none`,
	color: `inherit`,
	":hover": {
		textDecoration: `underline`,
	},
})

const StyledH2 = styled(H2)(({ theme }) => ({
	":not(:first-of-type)": {
		marginTop: theme.spacing.xxl,
	},
	marginBottom: theme.spacing.xs,
}))

export default function ContactPage() {
	const {
		content: {
			nodes: [contentData],
		},
		info: {
			nodes: [infoData],
		},
	} = useStaticQuery<any>(graphql`
		query ContactPage {
			content: allSanityContact(filter: { _id: { eq: "contact" } }) {
				nodes {
					description
				}
			}
			info: allSanityInfo(filter: { _id: { eq: "info" } }) {
				nodes {
					name
					emailAddress
					phoneNumber
				}
			}
		}
	`)

	const { description } = contentData
	const { phoneNumber, emailAddress, name } = infoData

	return (
		<>
			<Container
				css={(theme: TTheme) => ({
					padding: `${theme.spacing.m} 0`,
					[mq.medium]: {
						padding: `${theme.spacing.xxl} 0`,
					},
					[mq.large]: {
						padding: `${theme.spacing.xxxl} 0`,
					},
				})}
			>
				<H1
					css={{
						textAlign: `center`,
					}}
				>
					Contact Us
				</H1>
			</Container>
			<Container
				css={(theme: TTheme) => ({
					maxWidth: `900px`,
					marginLeft: `auto`,
					marginRight: `auto`,
					marginBottom: theme.spacing.l,
					[mq.medium]: {
						marginBottom: theme.spacing.xxl,
					},
					[mq.large]: {
						marginBottom: theme.spacing.xxxl,
					},
				})}
			>
				<div
					css={{
						display: `flex`,
						flexWrap: `wrap`,
					}}
				>
					<div
						css={(theme: TTheme) => ({
							flex: `1 0 100%`,
							marginBottom: theme.spacing.xxl,
							[mq.medium]: { flex: `0 0 50%`, order: 2 },
						})}
					>
						<Typography>{description}</Typography>
						<div
							css={(theme: TTheme) => ({
								marginTop: theme.spacing.xxxl,
							})}
						>
							<ContactForm />
						</div>
					</div>
					<div
						css={(theme: TTheme) => ({
							flex: `1 0 100%`,
							marginBottom: theme.spacing.l,
							[mq.medium]: {
								flex: `0 0 50%`,
								order: 1,
								paddingBottom: `10%`,
								display: `flex`,
								flexDirection: `column`,
								justifyContent: `center`,
							},
						})}
					>
						<StyledH2>By E-mail</StyledH2>
						<MailtoTypography href={`mailto:${emailAddress}`}>
							{emailAddress}
						</MailtoTypography>
						<StyledH2>By Telephone</StyledH2>
						<TelTypography href={`tel:${phoneNumber}`}>
							{phoneNumber}
						</TelTypography>
						<StyledH2>Social</StyledH2>
						<Typography>fb insta twitter</Typography>
					</div>
				</div>
			</Container>
		</>
	)
}
